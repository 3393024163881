import Layout from "../components/layout"
import SEO from "../components/seo"
import * as React from "react"
import { graphql } from "gatsby"
import TourCard, { ITourCardFragment } from "../components/tour-card"
import { ColumnIsOneFifth, Columns, ContainerOverview } from "../components/styled-components"

interface Props {
  data: {
    country: {
      name: string;
    };
    trips: {
      edges: {
        node: ITourCardFragment
      }[]
    };
  }
}

export default ({ data }: Props) => {
  return (
    <Layout>
      <SEO title={data.country.name}/>
      <ContainerOverview>
        <h2>{data.country.name}</h2>
        <Columns>
          {data.trips.edges.map((edge: any) => (
            <ColumnIsOneFifth key={edge.node.title}>
              <TourCard tour={edge.node}/>
            </ColumnIsOneFifth>
          ))}
        </Columns>
      </ContainerOverview>
    </Layout>
  )
}

export const query = graphql`
  query($countrySlug: String!, $name: String!) {
    country:contentfulCountry(fields: { countrySlug: { eq: $countrySlug } }) {
      name
    }
    trips:allContentfulTrip(
        limit: 25, 
        sort:{fields:internal___contentDigest}, 
        filter: {countries:{elemMatch:{name:{eq: $name}}}}
      ) {
      edges {
        node {
          ...TourCardFragment
        }
      }
    }
  }
`
